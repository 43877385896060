export enum CRUDAction {
  CREATE = 'CREATE',
  REMOVE = 'REMOVE',
  UPDATE = 'UPDATE',
  ADD = 'ADD'
}

export interface Notification {
  key?: number;
  message: React.ReactNode;
  options: any;
  dismissed?: boolean;
}

export interface GlobalState {
  error: string | null;
  carriers: {
    list: {
      carriers: Carrier[];
      loading: boolean;
      error: any;
    };
  };
  operators: {
    list: {
      operators: Operator[];
      loading: boolean;
      error: any;
    };
  };
  customers: {
    list: {
      customers: Customer[];
      loading: boolean;
      error: any;
    };
  };
  lockers: {
    list: {
      lockers: Locker[];
      loading: boolean;
      error: any;
    };
  };
  notifications: Notification[];
}
