import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { enableES5 } from 'immer';
import 'nprogress/nprogress.css';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider } from 'react-redux';
import { SettingsProvider } from 'src/context/SettingsContext';
import * as serviceWorker from 'src/serviceWorker';
import { restoreSettings } from 'src/utils/settings';
import App from './App';
import appStore from './store/store';

dayjs.locale('es');

enableES5();

const settings = restoreSettings();

ReactDOM.render(
  <Provider store={appStore}>
    <SettingsProvider settings={settings}>
      <App />
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
