import DayjsUtils from '@date-io/dayjs';
import {
  createStyles,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import { Router } from 'react-router-dom';
import Auth from './components/Auth';
import CookiesNotification from './components/CookiesNotification';
import GoogleAnalytics from './components/GoogleAnalytics';
import Notifier from './components/Notifier';
import ScrollReset from './components/ScrollReset';
import useSettings from './hooks/useSettings';
import Routes from './Routes';
import { createTheme } from './theme';

const history = createBrowserHistory();

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      }
    }
  })
);

function App() {
  useStyles();

  const { settings } = useSettings();

  return (
    <ThemeProvider theme={createTheme(settings)}>
      <StylesProvider>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <SnackbarProvider maxSnack={1}>
            <Router history={history}>
              <Auth>
                <Notifier />
                <ScrollReset />
                <GoogleAnalytics />
                <CookiesNotification />
                <Routes />
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
