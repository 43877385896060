import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalState, Notification } from 'src/store/types';

export const initialState: GlobalState = {
  error: null,
  carriers: {
    list: {
      carriers: [],
      loading: false,
      error: undefined
    }
  },
  operators: {
    list: {
      operators: [],
      loading: false,
      error: undefined
    }
  },
  customers: {
    list: {
      customers: [],
      loading: false,
      error: undefined
    }
  },
  lockers: {
    list: {
      lockers: [],
      loading: false,
      error: undefined
    }
  },
  notifications: []
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    getCarriersStart(state): void {
      state.carriers.list.loading = true;
      state.carriers.list.error = undefined;
    },
    getCarriersSuccess(state, action: PayloadAction<Carrier[]>): void {
      state.carriers.list.carriers = action.payload;
      state.carriers.list.loading = false;
    },
    getCarriersFailed(state, action: PayloadAction<any>): void {
      state.carriers.list.loading = false;
      state.carriers.list.error = action.payload;
    },
    getOperatorsStart(state): void {
      state.operators.list.loading = true;
      state.operators.list.error = undefined;
    },
    getOperatorsSuccess(state, action: PayloadAction<Operator[]>): void {
      state.operators.list.operators = action.payload;
      state.operators.list.loading = false;
    },
    getOperatorsFailed(state, action: PayloadAction<any>): void {
      state.operators.list.loading = false;
      state.operators.list.error = action.payload;
    },
    getCustomersStart(state): void {
      state.customers.list.loading = true;
      state.customers.list.error = undefined;
    },
    getCustomersSuccess(state, action: PayloadAction<Customer[]>): void {
      state.customers.list.customers = action.payload;
      state.customers.list.loading = false;
    },
    getCustomersFailed(state, action: PayloadAction<any>): void {
      state.customers.list.loading = false;
      state.customers.list.error = action.payload;
    },
    getLockersStart(state): void {
      state.lockers.list.loading = true;
      state.lockers.list.error = undefined;
    },
    getLockersSuccess(state, action: PayloadAction<Locker[]>): void {
      state.lockers.list.lockers = action.payload;
      state.lockers.list.loading = false;
    },
    getLockersFailed(state, action: PayloadAction<any>): void {
      state.lockers.list.loading = false;
      state.lockers.list.error = action.payload;
    },
    enqueueSnackbar(state, action: PayloadAction<Notification>): void {
      state.notifications.push({
        key: action.payload.options.key,
        ...action.payload
      });
    },
    closeSnackbar(state, action: PayloadAction<any>): void {
      state.notifications = state.notifications.map((notification) =>
        action.payload.dismissAll || notification.key === action.payload.key
          ? { ...notification, dismissed: true }
          : { ...notification }
      );
    },
    removeSnackbar(state, action: PayloadAction<any>): void {
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== action.payload
      );
    }
  }
});

// eslint-disable-next-line
export const {
  getOperatorsStart,
  getOperatorsSuccess,
  getOperatorsFailed,
  getCarriersStart,
  getCarriersSuccess,
  getCarriersFailed,
  getCustomersStart,
  getCustomersSuccess,
  getCustomersFailed,
  getLockersStart,
  getLockersSuccess,
  getLockersFailed,
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar
} = globalSlice.actions;

export default globalSlice.reducer;
