import jwtDecode from 'jwt-decode';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SplashScreen from 'src/components/SplashScreen';
import { setUserData } from 'src/store/account/account.thunks';
import { getAuthToken, isValidToken } from 'src/utils/auth';

const Auth: FC = ({ children }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      if (isValidToken()) {
        const user = jwtDecode<User>(getAuthToken()!);
        await dispatch(setUserData(user));
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return <>{children}</>;
};

export default Auth;
