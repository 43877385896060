export enum LogoTopColor {
  White = '/static/elok_logo_white.png',
  Black = '/static/elok_logo.png'
}

type LogoTopProps = {
  color?: LogoTopColor;
};

function Logo({ color, ...restProps }: LogoTopProps) {
  return (
    <img
      alt="Elok"
      src={color || LogoTopColor.White}
      style={{ maxHeight: 52, maxWidth: 200 }}
      {...restProps}
    />
  );
}

export default Logo;
