/* eslint-disable react/no-array-index-key */
import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import rolesGuard from './components/RolesGuard';
import DashboardLayout from './layouts/DashboardLayout';

type RoutesType = {
  exact?: boolean;
  path?: string;
  guard?: any;
  layout?: any;
  component?: any;
  routes?: RoutesType[];
};

const routesConfig: RoutesType[] = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/dashboard" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/forgot-password',
    component: lazy(() => import('./views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    path: '/p/reset/:token',
    component: lazy(() => import('./views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    path: '/privacy-policy',
    component: lazy(() => import('./views/terms/PrivacyPolicyView'))
  },
  {
    exact: true,
    path: '/terms-of-service',
    component: lazy(() => import('./views/terms/ToSView'))
  },
  {
    path: '/dashboard',
    guard: rolesGuard(['ADMIN']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('./views/pages/DashboardView'))
      }
    ]
  },
  {
    path: '/companies',
    guard: rolesGuard(['ADMIN']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/companies',
        component: lazy(
          () => import('./views/pages/CompaniesView/CompaniesList')
        )
      },
      {
        exact: true,
        path: '/companies/:id/:tab',
        component: lazy(
          () => import('./views/pages/CompaniesView/CompaniesDetail')
        )
      }
    ]
  },
  {
    path: '/lockers',
    guard: rolesGuard(['ADMIN']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/lockers',
        component: lazy(() => import('./views/pages/LockersView/LockersList'))
      },
      {
        exact: true,
        path: '/lockers/:id/:tab',
        component: lazy(() => import('./views/pages/LockersView/LockersDetail'))
      }
    ]
  },
  {
    path: '/boxes',
    guard: rolesGuard(['ADMIN']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/boxes/:id/:tab',
        component: lazy(() => import('./views/pages/LockersView/BoxDetail'))
      }
    ]
  },
  {
    path: '/customers',
    guard: rolesGuard(['ADMIN']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/customers',
        component: lazy(
          () => import('./views/pages/CustomersView/CustomersList')
        )
      },
      {
        exact: true,
        path: '/customers/:id/:tab',
        component: lazy(
          () => import('./views/pages/CustomersView/CustomersDetail')
        )
      }
    ]
  },
  {
    path: '/profile',
    guard: rolesGuard(['ADMIN']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/profile/:tab',
        component: lazy(() => import('./views/pages/ProfileView'))
      }
    ]
  },
  {
    path: '/users',
    guard: rolesGuard(['ADMIN']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/users',
        component: lazy(() => import('./views/pages/UsersView/UsersList'))
      },
      {
        exact: true,
        path: '/users/:id/:tab',
        component: lazy(() => import('./views/pages/UsersView/UsersDetail'))
      }
    ]
  },
  {
    path: '/carriers',
    guard: rolesGuard(['ADMIN']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/carriers',
        component: lazy(() => import('./views/pages/CarriersView/CarriersList'))
      },
      {
        exact: true,
        path: '/carriers/:id/:tab',
        component: lazy(
          () => import('./views/pages/CarriersView/CarriersDetail')
        )
      }
    ]
  },
  {
    path: '/operators',
    guard: rolesGuard(['ADMIN']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/operators',
        component: lazy(
          () => import('./views/pages/OperatorsView/OperatorsList')
        )
      },
      {
        exact: true,
        path: '/operators/:id/:tab',
        component: lazy(
          () => import('./views/pages/OperatorsView/OperatorsDetail')
        )
      }
    ]
  },
  {
    path: '/services',
    guard: rolesGuard(['ADMIN']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/services',
        component: lazy(() => import('./views/pages/ServicesView/ServicesList'))
      },
      {
        exact: true,
        path: '/services/:id/:tab',
        component: lazy(
          () => import('./views/pages/ServicesView/ServicesDetail')
        )
      }
    ]
  },
  {
    path: '*',
    guard: GuestGuard,
    routes: [
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes: RoutesType[]) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
