import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServiceMediasState } from './types';

const initialServiceMediasListState: ServiceMedia[] = [];

export const initialState: ServiceMediasState = {
  list: {
    serviceMedias: initialServiceMediasListState,
    loading: false,
    error: undefined
  }
};

const serviceMedia = createSlice({
  name: 'serviceMedia',
  initialState,
  reducers: {
    getServiceMediasStart(state): void {
      state.list.loading = true;
      state.list.error = undefined;
    },
    getServiceMediasSuccess(
      state,
      action: PayloadAction<ServiceMedia[]>
    ): void {
      state.list.serviceMedias = action.payload;
      state.list.loading = false;
    },
    getServiceMediasFailed(state, action: PayloadAction<any>): void {
      state.list.serviceMedias = initialServiceMediasListState;
      state.list.loading = false;
      state.list.error = action.payload;
    }
  }
});

export const {
  getServiceMediasStart,
  getServiceMediasSuccess,
  getServiceMediasFailed
} = serviceMedia.actions;

export default serviceMedia.reducer;
