import { RootState } from 'src/store/rootReducer';
import { GlobalState } from 'src/store/types';

export const selectGlobalState = (state: RootState): GlobalState =>
  state.global;
export const selectNotifications = (state: RootState): any =>
  selectGlobalState(state).notifications;

export const selectOperators = (state: RootState): Operator[] =>
  selectGlobalState(state).operators.list.operators;
export const selectOperatorsIsLoading = (state: RootState): boolean =>
  selectGlobalState(state).operators.list.loading;
export const selectOperatorsError = (state: RootState): any | null =>
  selectGlobalState(state).operators.list.error;

export const selectCarriers = (state: RootState): Carrier[] =>
  selectGlobalState(state).carriers.list.carriers;
export const selectCarriersIsLoading = (state: RootState): boolean =>
  selectGlobalState(state).carriers.list.loading;
export const selectCarriersError = (state: RootState): any | null =>
  selectGlobalState(state).carriers.list.error;

export const selectLockers = (state: RootState): Locker[] =>
  selectGlobalState(state).lockers.list.lockers;
export const selectLockersIsLoading = (state: RootState): boolean =>
  selectGlobalState(state).lockers.list.loading;
export const selectLockersError = (state: RootState): any | null =>
  selectGlobalState(state).lockers.list.error;

export const selectCustomers = (state: RootState): Customer[] =>
  selectGlobalState(state).customers.list.customers;
export const selectCustomersIsLoading = (state: RootState): boolean =>
  selectGlobalState(state).customers.list.loading;
export const selectCustomersError = (state: RootState): any | null =>
  selectGlobalState(state).customers.list.error;
