import React from 'react';

function Logo(props) {
  return (
    <img
      alt="iComm Solutions"
      src="/static/elok_logo.png"
      style={{ maxHeight: 100, maxWidth: '95%' }}
      {...props}
    />
  );
}

export default Logo;
