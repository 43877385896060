import JwtDecode from 'jwt-decode';

export const setAuthToken = (token: string) =>
  localStorage.setItem('token', token);

export const getAuthToken = () => localStorage.getItem('token');

export const deleteAuthToken = () => localStorage.removeItem('token');

export const isValidToken = () => {
  const token = getAuthToken();
  if (token === null) {
    return false;
  }
  const decoded = JwtDecode<AuthUser>(token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};
