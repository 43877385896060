import { combineReducers } from '@reduxjs/toolkit';
import accountReducer from 'src/store/account/account.slice';
import carrierReducer from 'src/store/carrier/carrier.slice';
import customerReducer from 'src/store/customer/customer.slice';
import globalReducer from 'src/store/global.slice';
import lockerReducer from 'src/store/locker/locker.slice';
import operatorReducer from 'src/store/operator/operator.slice';
import serviceReducer from 'src/store/service/service.slice';
import serviceMediaReducer from 'src/store/service_media/service_media.slice';
import serviceStatusReducer from 'src/store/service_status/service_status.slice';
import userReducer from 'src/store/user/user.slice';

const rootReducer = combineReducers({
  global: globalReducer,
  account: accountReducer,
  user: userReducer,
  carrier: carrierReducer,
  operator: operatorReducer,
  locker: lockerReducer,
  customer: customerReducer,
  service: serviceReducer,
  serviceStatus: serviceStatusReducer,
  serviceMedia: serviceMediaReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
