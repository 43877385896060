import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AccountState } from 'src/store/account/types';
import { RootState } from 'src/store/rootReducer';

type Props = (allowedRoles?: Role[]) => FC;

const rolesGuard: Props =
  (allowedRoles) =>
  ({ children }) => {
    const account = useSelector<RootState, AccountState>(
      (state) => state.account
    );

    if (
      allowedRoles &&
      allowedRoles.length > 0 &&
      account.user &&
      allowedRoles.includes(account.user.role)
    ) {
      return <>{children}</>;
    }

    return <Redirect to="/login" />;
  };

export default rolesGuard;
