import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CRUDAction } from 'src/store/types';
import { LockerState } from './types';

const initialPaginationListState = { results: [], count: 0 };

export const initialState: LockerState = {
  list: {
    lockers: initialPaginationListState,
    loading: false,
    error: undefined
  },
  detail: {
    locker: undefined,
    loading: false,
    error: undefined,
    boxes: {
      status: {
        boxes: [],
        loading: false,
        error: undefined
      },
      detail: {
        box: undefined,
        loading: false,
        error: undefined
      },
      services: {
        services: initialPaginationListState,
        loading: false,
        error: undefined
      }
    },
    services: {
      services: initialPaginationListState,
      loading: false,
      error: undefined
    }
  },
  crud: {
    loading: false,
    error: undefined,
    success: undefined
  }
};

const locker = createSlice({
  name: 'locker',
  initialState,
  reducers: {
    getLockersStart(state): void {
      state.list.loading = true;
      state.list.error = undefined;
    },
    getLockersSuccess(
      state,
      action: PayloadAction<PaginationResponse<Locker>>
    ): void {
      state.list.lockers = action.payload;
      state.list.loading = false;
    },
    getLockersFailed(state, action: PayloadAction<any>): void {
      state.list.lockers = initialPaginationListState;
      state.list.loading = false;
      state.list.error = action.payload;
    },

    getLockerStart(state): void {
      state.detail.loading = true;
      state.detail.error = undefined;
      state.detail.locker = undefined;
    },
    getLockerSuccess(state, action: PayloadAction<Locker>): void {
      state.detail.loading = false;
      state.detail.locker = action.payload;
    },
    getLockerFailed(state, action: PayloadAction<any>): void {
      state.detail.loading = false;
      state.detail.error = action.payload;
    },

    createLockerStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
      state.crud.action = CRUDAction.ADD;
      state.crud.success = undefined;
    },
    createLockerSuccess(state, action: PayloadAction<Locker>): void {
      state.list.lockers.results.unshift(action.payload);
      state.list.lockers.count++;
      state.crud.success = true;
      state.crud.loading = false;
    },
    createLockerFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.success = false;
      state.crud.error = action.payload;
    },

    deleteLockerStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
      state.crud.action = CRUDAction.REMOVE;
      state.crud.success = undefined;
    },
    deleteLockerSuccess(state, action: PayloadAction<number>): void {
      state.list.lockers.results = state.list.lockers.results.filter(
        (lock) => lock.id !== action.payload
      );
      state.list.lockers.count--;
      state.crud.success = true;
      state.crud.loading = false;
    },
    deleteLockerFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.success = false;
      state.crud.error = action.payload;
    },

    updateLockerStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
      state.crud.action = CRUDAction.UPDATE;
      state.crud.success = undefined;
    },
    updateLockerSuccess(state, action: PayloadAction<Locker>): void {
      const foundIndex = state.list.lockers.results.findIndex(
        (lock) => lock.id === action.payload.id
      );
      state.list.lockers.results[foundIndex] = action.payload;
      state.crud.success = true;
      state.detail.locker = action.payload;
      state.crud.loading = false;
    },
    updateLockerFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.success = false;
      state.crud.error = action.payload;
    },
    getLockerBoxesStart(state): void {
      state.detail.boxes.status.loading = true;
      state.detail.boxes.status.error = undefined;
    },
    getLockerBoxesSuccess(state, action: PayloadAction<Box[]>): void {
      state.detail.boxes.status.boxes = action.payload;
      state.detail.boxes.status.loading = false;
    },
    getLockerBoxesFailed(state, action: PayloadAction<any>): void {
      state.detail.boxes.status.boxes = [];
      state.detail.boxes.status.loading = false;
      state.detail.boxes.status.error = action.payload;
    },
    getLockerBoxStart(state): void {
      state.detail.boxes.detail.loading = true;
      state.detail.boxes.detail.error = undefined;
    },
    getLockerBoxSuccess(state, action: PayloadAction<Box>): void {
      state.detail.boxes.detail.box = action.payload;
      state.detail.boxes.detail.loading = false;
    },
    getLockerBoxFailed(state, action: PayloadAction<any>): void {
      state.detail.boxes.detail.box = undefined;
      state.detail.boxes.detail.loading = false;
      state.detail.boxes.detail.error = action.payload;
    },
    getLockerBoxServicesStart(state): void {
      state.detail.boxes.services.loading = true;
      state.detail.boxes.services.error = undefined;
    },
    getLockerBoxServicesSuccess(
      state,
      action: PayloadAction<PaginationResponse<Service>>
    ): void {
      state.detail.boxes.services.services = action.payload;
      state.detail.boxes.services.loading = false;
    },
    getLockerBoxServicesFailed(state, action: PayloadAction<any>): void {
      state.detail.boxes.services.services = initialPaginationListState;
      state.detail.boxes.services.loading = false;
      state.detail.boxes.services.error = action.payload;
    },
    getLockerServicesStart(state): void {
      state.detail.services.loading = true;
      state.detail.services.error = undefined;
    },
    getLockerServicesSuccess(
      state,
      action: PayloadAction<PaginationResponse<Service>>
    ): void {
      state.detail.services.services = action.payload;
      state.detail.services.loading = false;
    },
    getLockerServicesFailed(state, action: PayloadAction<any>): void {
      state.detail.services.services = initialPaginationListState;
      state.detail.services.loading = false;
      state.detail.services.error = action.payload;
    },
    openBoxStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
    },
    openBoxSuccess(state): void {
      state.crud.loading = false;
    },
    openBoxFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.error = action.payload;
    },
    setMaintenanceBoxStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
    },
    setMaintenanceBoxSuccess(state): void {
      state.crud.loading = false;
      if (state.detail.boxes.detail.box) {
        state.detail.boxes.detail.box.status = 'MAINTENANCE';
      }
    },
    setMaintenanceBoxFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.error = action.payload;
    },
    setAvailableBoxStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
    },
    setAvailableBoxSuccess(state): void {
      state.crud.loading = false;
      if (state.detail.boxes.detail.box) {
        state.detail.boxes.detail.box.status = 'AVAILABLE';
      }
    },
    setAvailableBoxFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.error = action.payload;
    },
    resetCrud(state): void {
      state.crud = initialState.crud;
    }
  }
});

export const {
  getLockersStart,
  getLockersSuccess,
  getLockersFailed,
  getLockerStart,
  getLockerSuccess,
  getLockerFailed,
  createLockerStart,
  createLockerSuccess,
  createLockerFailed,
  deleteLockerStart,
  deleteLockerSuccess,
  deleteLockerFailed,
  updateLockerStart,
  updateLockerSuccess,
  updateLockerFailed,
  getLockerBoxesStart,
  getLockerBoxesSuccess,
  getLockerBoxesFailed,
  getLockerBoxStart,
  getLockerBoxSuccess,
  getLockerBoxFailed,
  getLockerBoxServicesStart,
  getLockerBoxServicesSuccess,
  getLockerBoxServicesFailed,
  getLockerServicesStart,
  getLockerServicesSuccess,
  getLockerServicesFailed,
  openBoxFailed,
  openBoxStart,
  openBoxSuccess,
  setAvailableBoxFailed,
  setAvailableBoxStart,
  setAvailableBoxSuccess,
  setMaintenanceBoxFailed,
  setMaintenanceBoxStart,
  setMaintenanceBoxSuccess,
  resetCrud
} = locker.actions;

export default locker.reducer;
