import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AccountState } from 'src/store/account/types';
import { RootState } from 'src/store/rootReducer';

const GuestGuard: FC = ({ children }) => {
  const account = useSelector<RootState, AccountState>(
    (state) => state.account
  );

  if (account.user) {
    return <Redirect to="/dashboard" />;
  }

  return <>{children}</>;
};

export default GuestGuard;
