import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountState } from './types';

export const initialState: AccountState = {
  user: null,
  error: null,
  loading: false
};

const account = createSlice({
  name: 'account',
  initialState,
  reducers: {
    loginStart(state): void {
      state.loading = true;
      state.error = null;
    },
    loginSuccess(state, action: PayloadAction<User>): void {
      state.user = action.payload;
      state.loading = false;
      state.error = null;
    },
    loginFailed(state, action: PayloadAction<any>): void {
      state.user = null;
      state.loading = false;
      state.error = action.payload;
    },
    setUserDataStart(state): void {
      state.error = null;
      state.loading = true;
    },
    setUserDataSuccess(
      state,
      action: PayloadAction<AccountState['user']>
    ): void {
      state.user = action.payload;
      state.loading = false;
      state.error = null;
    },
    setUserDataFailed(state, action: PayloadAction<any>): void {
      state.error = action.payload;
      state.loading = false;
    },
    logoutStart(state): void {
      state.error = null;
      state.loading = true;
    },
    logoutSuccess(state): void {
      state.user = null;
      state.loading = false;
      state.error = null;
    },
    logoutFailed(state, action: PayloadAction<any>): void {
      state.error = action.payload;
      state.loading = false;
    },
    updateProfileStart(state): void {
      state.error = null;
      state.loading = true;
    },
    updateProfileSuccess(state, action: PayloadAction<User>): void {
      state.user = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateProfileFailed(state, action: PayloadAction<any>): void {
      state.error = action.payload;
      state.loading = false;
    },
    updatePasswordStart(state): void {
      state.error = null;
      state.loading = true;
    },
    updatePasswordSuccess(state): void {
      state.loading = false;
      state.error = null;
    },
    updatePasswordFailed(state, action: PayloadAction<any>): void {
      state.error = action.payload;
      state.loading = false;
    },
    updatePhotoStart(state): void {
      state.error = null;
      state.loading = true;
    },
    updatePhotoSuccess(state, action: PayloadAction<User>): void {
      state.user = action.payload;
      state.loading = false;
      state.error = null;
    },
    updatePhotoFailed(state, action: PayloadAction<any>): void {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const {
  loginStart,
  loginSuccess,
  loginFailed,
  setUserDataStart,
  setUserDataSuccess,
  setUserDataFailed,
  logoutStart,
  logoutSuccess,
  logoutFailed,
  updateProfileStart,
  updateProfileSuccess,
  updateProfileFailed,
  updatePasswordStart,
  updatePasswordSuccess,
  updatePasswordFailed,
  updatePhotoStart,
  updatePhotoSuccess,
  updatePhotoFailed
} = account.actions;

export default account.reducer;
