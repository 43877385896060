export const getRoleName = (role: Role) => {
  switch (role) {
    case 'ADMIN':
      return 'Administrador';
    case 'SUPERVISOR':
      return 'Supervisor';
    default:
      return '';
  }
};
