import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CRUDAction } from 'src/store/types';
import { CustomerState } from './types';

const initialCustomersListState = { results: [], count: 0 };

export const initialState: CustomerState = {
  list: {
    customers: initialCustomersListState,
    loading: false,
    error: undefined
  },
  detail: {
    customer: undefined,
    loading: false,
    error: undefined
  },
  crud: {
    loading: false,
    error: undefined,
    success: undefined
  }
};

const customer = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    getCustomersStart(state): void {
      state.list.loading = true;
      state.list.error = undefined;
    },
    getCustomersSuccess(
      state,
      action: PayloadAction<PaginationResponse<Customer>>
    ): void {
      state.list.customers = action.payload;
      state.list.loading = false;
    },
    getCustomersFailed(state, action: PayloadAction<any>): void {
      state.list.customers = initialCustomersListState;
      state.list.loading = false;
      state.list.error = action.payload;
    },

    getCustomerStart(state): void {
      state.detail.loading = true;
      state.detail.error = undefined;
      state.detail.customer = undefined;
    },
    getCustomerSuccess(state, action: PayloadAction<Customer>): void {
      state.detail.loading = false;
      state.detail.customer = action.payload;
    },
    getCustomerFailed(state, action: PayloadAction<any>): void {
      state.detail.loading = false;
      state.detail.error = action.payload;
    },

    createCustomerStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
      state.crud.action = CRUDAction.ADD;
      state.crud.success = undefined;
    },
    createCustomerSuccess(state, action: PayloadAction<Customer>): void {
      state.list.customers.results.unshift(action.payload);
      state.list.customers.count++;
      state.crud.success = true;
      state.crud.loading = false;
    },
    createCustomerFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.success = false;
      state.crud.error = action.payload;
    },

    deleteCustomerStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
      state.crud.action = CRUDAction.REMOVE;
      state.crud.success = undefined;
    },
    deleteCustomerSuccess(state, action: PayloadAction<number>): void {
      state.list.customers.results = state.list.customers.results.filter(
        (lock) => lock.id !== action.payload
      );
      state.list.customers.count--;
      state.crud.success = true;
      state.crud.loading = false;
    },
    deleteCustomerFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.success = false;
      state.crud.error = action.payload;
    },

    updateCustomerStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
      state.crud.action = CRUDAction.UPDATE;
      state.crud.success = undefined;
    },
    updateCustomerSuccess(state, action: PayloadAction<Customer>): void {
      const foundIndex = state.list.customers.results.findIndex(
        (lock) => lock.id === action.payload.id
      );
      state.list.customers.results[foundIndex] = action.payload;
      state.crud.success = true;
      state.detail.customer = action.payload;
      state.crud.loading = false;
    },
    updateCustomerFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.success = false;
      state.crud.error = action.payload;
    },

    resetCrud(state): void {
      state.crud = initialState.crud;
    }
  }
});

export const {
  getCustomersStart,
  getCustomersSuccess,
  getCustomersFailed,
  getCustomerStart,
  getCustomerSuccess,
  getCustomerFailed,
  createCustomerStart,
  createCustomerSuccess,
  createCustomerFailed,
  deleteCustomerStart,
  deleteCustomerSuccess,
  deleteCustomerFailed,
  updateCustomerStart,
  updateCustomerSuccess,
  updateCustomerFailed,
  resetCrud
} = customer.actions;

export default customer.reducer;
