import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CRUDAction } from 'src/store/types';
import { UserState } from 'src/store/user/types';

const initialUsersListState = {
  results: [],
  count: 0
};
export const initialState: UserState = {
  list: {
    users: initialUsersListState,
    loading: false,
    error: undefined
  },
  detail: {
    user: undefined,
    loading: false,
    error: undefined
  },
  crud: {
    loading: false,
    error: undefined,
    success: undefined
  }
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUsersStart(state): void {
      state.list.loading = true;
      state.list.error = undefined;
    },
    getUsersSuccess(
      state,
      action: PayloadAction<PaginationResponse<User>>
    ): void {
      state.list.users = action.payload;
      state.list.loading = false;
    },
    getUsersFailed(state, action: PayloadAction<any>): void {
      state.list.users = initialUsersListState;
      state.list.loading = false;
      state.list.error = action.payload;
    },
    getUserStart(state): void {
      state.detail.loading = true;
      state.detail.error = undefined;
      state.detail.user = undefined;
    },
    getUserSuccess(state, action: PayloadAction<User>): void {
      state.detail.loading = false;
      state.detail.user = action.payload;
    },
    getUserFailed(state, action: PayloadAction<any>): void {
      state.detail.loading = false;
      state.detail.error = action.payload;
    },
    createUserStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
      state.crud.action = CRUDAction.ADD;
      state.crud.success = undefined;
    },
    createUserSuccess(state, action: PayloadAction<User>): void {
      state.list.users.results.push(action.payload);
      state.list.users.count++;
      state.crud.success = true;
      state.crud.loading = false;
    },
    createUserFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.success = false;
      state.crud.error = action.payload;
    },
    deleteUserStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
      state.crud.action = CRUDAction.REMOVE;
      state.crud.success = undefined;
    },
    deleteUserSuccess(state, action: PayloadAction<number>): void {
      state.list.users.results = state.list.users.results.filter(
        (dent) => dent.id !== action.payload
      );
      state.list.users.count--;
      state.crud.success = true;
      state.crud.loading = false;
    },
    deleteUserFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.success = false;
      state.crud.error = action.payload;
    },
    updateUserStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
      state.crud.action = CRUDAction.UPDATE;
      state.crud.success = undefined;
    },
    updateUserSuccess(state, action: PayloadAction<User>): void {
      const foundIndex = state.list.users.results.findIndex(
        (dent) => dent.id === action.payload.id
      );
      state.list.users.results[foundIndex] = action.payload;
      state.crud.success = true;
      state.detail.user = action.payload;
      state.crud.loading = false;
    },
    updateUserFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.success = false;
      state.crud.error = action.payload;
    },
    resetCrud(state): void {
      state.crud = initialState.crud;
    }
  }
});

export const {
  getUsersStart,
  getUsersSuccess,
  getUsersFailed,
  getUserStart,
  getUserSuccess,
  getUserFailed,
  createUserStart,
  createUserSuccess,
  createUserFailed,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailed,
  updateUserStart,
  updateUserSuccess,
  updateUserFailed,
  resetCrud
} = user.actions;
export default user.reducer;
