import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OperatorState } from 'src/store/operator/types';
import { CRUDAction } from 'src/store/types';

const initialOperatorsListState = {
  results: [],
  count: 0
};
export const initialState: OperatorState = {
  list: {
    operators: initialOperatorsListState,
    loading: false,
    error: undefined
  },
  detail: {
    operator: undefined,
    loading: false,
    error: undefined
  },
  crud: {
    loading: false,
    error: undefined,
    success: undefined
  }
};

const operator = createSlice({
  name: 'operator',
  initialState,
  reducers: {
    getOperatorsStart(state): void {
      state.list.loading = true;
      state.list.error = undefined;
    },
    getOperatorsSuccess(
      state,
      action: PayloadAction<PaginationResponse<Operator>>
    ): void {
      state.list.operators = action.payload;
      state.list.loading = false;
    },
    getOperatorsFailed(state, action: PayloadAction<any>): void {
      state.list.operators = initialOperatorsListState;
      state.list.loading = false;
      state.list.error = action.payload;
    },
    getOperatorStart(state): void {
      state.detail.loading = true;
      state.detail.error = undefined;
      state.detail.operator = undefined;
    },
    getOperatorSuccess(state, action: PayloadAction<Operator>): void {
      state.detail.loading = false;
      state.detail.operator = action.payload;
    },
    getOperatorFailed(state, action: PayloadAction<any>): void {
      state.detail.loading = false;
      state.detail.error = action.payload;
    },
    createOperatorStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
      state.crud.action = CRUDAction.ADD;
      state.crud.success = undefined;
    },
    createOperatorSuccess(state, action: PayloadAction<Operator>): void {
      state.list.operators.results.push(action.payload);
      state.list.operators.count++;
      state.crud.success = true;
      state.crud.loading = false;
    },
    createOperatorFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.success = false;
      state.crud.error = action.payload;
    },
    deleteOperatorStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
      state.crud.action = CRUDAction.REMOVE;
      state.crud.success = undefined;
    },
    deleteOperatorSuccess(state, action: PayloadAction<number>): void {
      state.list.operators.results = state.list.operators.results.filter(
        (dent) => dent.id !== action.payload
      );
      state.list.operators.count--;
      state.crud.success = true;
      state.crud.loading = false;
    },
    deleteOperatorFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.success = false;
      state.crud.error = action.payload;
    },
    updateOperatorStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
      state.crud.action = CRUDAction.UPDATE;
      state.crud.success = undefined;
    },
    updateOperatorSuccess(state, action: PayloadAction<Operator>): void {
      const foundIndex = state.list.operators.results.findIndex(
        (dent) => dent.id === action.payload.id
      );
      state.list.operators.results[foundIndex] = action.payload;
      state.crud.success = true;
      state.detail.operator = action.payload;
      state.crud.loading = false;
    },
    updateOperatorFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.success = false;
      state.crud.error = action.payload;
    },
    resetCrud(state): void {
      state.crud = initialState.crud;
    }
  }
});

export const {
  getOperatorsStart,
  getOperatorsSuccess,
  getOperatorsFailed,
  getOperatorStart,
  getOperatorSuccess,
  getOperatorFailed,
  createOperatorStart,
  createOperatorSuccess,
  createOperatorFailed,
  deleteOperatorStart,
  deleteOperatorSuccess,
  deleteOperatorFailed,
  updateOperatorStart,
  updateOperatorSuccess,
  updateOperatorFailed,
  resetCrud
} = operator.actions;
export default operator.reducer;
