import jwtDecode from 'jwt-decode';
import {
  loginUser,
  updatePasswordRequest,
  updatePhotoRequest,
  updateProfileRequest
} from 'src/api/auth';
import { deleteAuthToken, setAuthToken } from 'src/utils/auth';
import { AppThunk } from '../store';
import {
  loginFailed,
  loginStart,
  loginSuccess,
  logoutFailed,
  logoutStart,
  logoutSuccess,
  setUserDataFailed,
  setUserDataStart,
  setUserDataSuccess,
  updatePasswordFailed,
  updatePasswordStart,
  updatePasswordSuccess,
  updatePhotoFailed,
  updatePhotoStart,
  updatePhotoSuccess,
  updateProfileFailed,
  updateProfileStart,
  updateProfileSuccess
} from './account.slice';
import { AccountState } from './types';

export const login =
  (username: string, password: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(loginStart());
      const userToken = await loginUser(username, password);
      setAuthToken(userToken.token);
      const user = jwtDecode<User>(userToken.token);
      dispatch(loginSuccess(user));
    } catch (error) {
      dispatch(loginFailed(error));
    }
  };

export const setUserData =
  (user: AccountState['user']): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setUserDataStart());
      dispatch(setUserDataSuccess(user));
    } catch (error) {
      dispatch(setUserDataFailed(error));
    }
  };

export const logout =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(logoutStart());
      deleteAuthToken();
      dispatch(logoutSuccess());
    } catch (error) {
      dispatch(logoutFailed(error));
    }
  };

export const updateProfile =
  (update: Partial<AccountState['user']>): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(updateProfileStart());
      const user = await updateProfileRequest(update);
      dispatch(updateProfileSuccess(user));
    } catch (error) {
      dispatch(updateProfileFailed(error));
    }
  };

export const updatePassword =
  (update: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(updatePasswordStart());
      await updatePasswordRequest(update);
      dispatch(updatePasswordSuccess());
    } catch (error) {
      dispatch(updatePasswordFailed(error));
    }
  };

export const updatePhoto =
  (file: any): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append('file', file.file);
      dispatch(updatePhotoStart());
      const user = await updatePhotoRequest(formData);
      dispatch(updatePhotoSuccess(user));
    } catch (error) {
      dispatch(updatePhotoFailed(error));
    }
  };
