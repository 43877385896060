import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CRUDAction } from 'src/store/types';
import { ServiceState } from './types';

const initialServicesListState = { results: [], count: 0 };

export const initialState: ServiceState = {
  list: {
    services: initialServicesListState,
    loading: false,
    error: undefined
  },
  detail: {
    service: undefined,
    loading: false,
    error: undefined
  },
  crud: {
    loading: false,
    error: undefined,
    success: undefined
  }
};

const service = createSlice({
  name: 'service',
  initialState,
  reducers: {
    getServicesStart(state): void {
      state.list.loading = true;
      state.list.error = undefined;
    },
    getServicesSuccess(
      state,
      action: PayloadAction<PaginationResponse<Service>>
    ): void {
      state.list.services = action.payload;
      state.list.loading = false;
    },
    getServicesFailed(state, action: PayloadAction<any>): void {
      state.list.services = initialServicesListState;
      state.list.loading = false;
      state.list.error = action.payload;
    },

    getServiceStart(state): void {
      state.detail.loading = true;
      state.detail.error = undefined;
      state.detail.service = undefined;
    },
    getServiceSuccess(state, action: PayloadAction<Service>): void {
      state.detail.loading = false;
      state.detail.service = action.payload;
    },
    getServiceFailed(state, action: PayloadAction<any>): void {
      state.detail.loading = false;
      state.detail.error = action.payload;
    },

    createServiceStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
      state.crud.action = CRUDAction.ADD;
      state.crud.success = undefined;
    },
    createServiceSuccess(state, action: PayloadAction<Service>): void {
      state.list.services.results.unshift(action.payload);
      state.list.services.count++;
      state.crud.success = true;
      state.crud.loading = false;
    },
    createServiceFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.success = false;
      state.crud.error = action.payload;
    },

    deleteServiceStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
      state.crud.action = CRUDAction.REMOVE;
      state.crud.success = undefined;
    },
    deleteServiceSuccess(state, action: PayloadAction<number>): void {
      state.list.services.results = state.list.services.results.filter(
        (lock) => lock.id !== action.payload
      );
      state.list.services.count--;
      state.crud.success = true;
      state.crud.loading = false;
    },
    deleteServiceFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.success = false;
      state.crud.error = action.payload;
    },

    updateServiceStart(state): void {
      state.crud.loading = true;
      state.crud.error = undefined;
      state.crud.action = CRUDAction.UPDATE;
      state.crud.success = undefined;
    },
    updateServiceSuccess(state, action: PayloadAction<Service>): void {
      const foundIndex = state.list.services.results.findIndex(
        (lock) => lock.id === action.payload.id
      );
      state.list.services.results[foundIndex] = action.payload;
      state.crud.success = true;
      state.detail.service = action.payload;
      state.crud.loading = false;
    },
    updateServiceFailed(state, action: PayloadAction<any>): void {
      state.crud.loading = false;
      state.crud.success = false;
      state.crud.error = action.payload;
    },

    resetCrud(state): void {
      state.crud = initialState.crud;
    }
  }
});

export const {
  getServicesStart,
  getServicesSuccess,
  getServicesFailed,
  getServiceStart,
  getServiceSuccess,
  getServiceFailed,
  createServiceStart,
  createServiceSuccess,
  createServiceFailed,
  deleteServiceStart,
  deleteServiceSuccess,
  deleteServiceFailed,
  updateServiceStart,
  updateServiceSuccess,
  updateServiceFailed,
  resetCrud
} = service.actions;

export default service.reducer;
