import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import { Action } from 'redux';
import logger from 'redux-logger';
import rootReducer, { RootState } from 'src/store/rootReducer';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== 'production'
});

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export type AppDispatch = typeof store.dispatch;

export default store;
