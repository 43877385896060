import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServiceStatusesState } from './types';

const initialServiceStatusesListState: ServiceStatus[] = [];

export const initialState: ServiceStatusesState = {
  list: {
    serviceStatuses: initialServiceStatusesListState,
    loading: false,
    error: undefined
  }
};

const service = createSlice({
  name: 'serviceStatus',
  initialState,
  reducers: {
    getServiceStatusesStart(state): void {
      state.list.loading = true;
      state.list.error = undefined;
    },
    getServiceStatusesSuccess(
      state,
      action: PayloadAction<ServiceStatus[]>
    ): void {
      state.list.serviceStatuses = action.payload;
      state.list.loading = false;
    },
    getServiceStatusesFailed(state, action: PayloadAction<any>): void {
      state.list.serviceStatuses = initialServiceStatusesListState;
      state.list.loading = false;
      state.list.error = action.payload;
    }
  }
});

export const {
  getServiceStatusesStart,
  getServiceStatusesSuccess,
  getServiceStatusesFailed
} = service.actions;

export default service.reducer;
